import { WppTypography } from '@platform-ui-kit/components-library-react'

import { ReactComponent as IconRectangle } from 'assets/icons/rectangle.svg'
import styles from 'components/assistant/chat/chatBubble/question/Question.module.scss'
import { Flex } from 'components/common/flex/Flex'

interface Props {
  text: string
}

export const Question = ({ text }: Props) => {
  return (
    <Flex>
      <Flex className={styles.chatBubbleQuestion} direction="row" wrap="nowrap" gap={12}>
        <WppTypography tag="p" type="s-body" className={styles.text}>
          {text}
        </WppTypography>
      </Flex>
      <IconRectangle className={styles.rectangle} />
    </Flex>
  )
}
