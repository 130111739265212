// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cPn5q{width:100%;margin-bottom:8px;margin-left:20px;padding:12px 16px;background-color:var(--wpp-primary-color-100);border-radius:var(--wpp-border-radius-m);border-top-right-radius:0}.rU8lT{width:100%;white-space:pre-line}.vbTAC{flex-shrink:0}.BPHDu{fill:var(--wpp-primary-color-100);min-width:4px;transform:rotate3d(0, 1, 0, 180deg)}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatBubble/question/Question.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,iBAAA,CACA,gBAAA,CACA,iBAAA,CACA,6CAAA,CACA,wCAAA,CACA,yBAAA,CAGF,OACE,UAAA,CACA,oBAAA,CAGF,OACE,aAAA,CAGF,OACE,iCAAA,CACA,aAAA,CACA,mCAAA","sourcesContent":[".chatBubbleQuestion {\n  width: 100%;\n  margin-bottom: 8px;\n  margin-left: 20px;\n  padding: 12px 16px;\n  background-color: var(--wpp-primary-color-100);\n  border-radius: var(--wpp-border-radius-m);\n  border-top-right-radius: 0;\n}\n\n.text {\n  width: 100%;\n  white-space: pre-line;\n}\n\n.avatar {\n  flex-shrink: 0;\n}\n\n.rectangle {\n  fill: var(--wpp-primary-color-100);\n  min-width: 4px;\n  transform: rotate3d(0, 1, 0, 180deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatBubbleQuestion": "cPn5q",
	"text": "rU8lT",
	"avatar": "vbTAC",
	"rectangle": "BPHDu"
};
export default ___CSS_LOADER_EXPORT___;
