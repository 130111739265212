import {
  WppActionButton,
  WppIconClose,
  WppIconStatisticDocument,
  WppSpinner,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'

import styles from './FileList.module.scss'

export interface FileUploadProps {
  filesToUpload: File[]
  loading: boolean
  disabled?: boolean
  clearFile: (idx: number) => void
}

export const FileList = ({ filesToUpload, loading, disabled = false, clearFile }: FileUploadProps) => {
  const formatSize = (bytes: number) => {
    if (bytes === 0) return '0 Bytes'

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    const i = Math.floor(Math.log(bytes) / Math.log(1024))

    return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i]
  }

  return (
    <>
      {filesToUpload.length > 0 && (
        <div className={styles.filesWrapper}>
          {filesToUpload.map((el, idx) => (
            <Flex key={idx} align="center" justify="between" gap={4} className={styles.file}>
              <Flex gap={4} align="center" className={styles.textContainer}>
                {loading ? <WppSpinner /> : <WppIconStatisticDocument size="s" />}
                <WppTypography type="xs-body" className={styles.fileText}>
                  {el.name}
                </WppTypography>
                <WppTypography type="xs-body" className={styles.fileSize}>
                  {formatSize(el.size)}
                </WppTypography>
              </Flex>

              {disabled ? (
                <WppTooltip text="Once a message is sent, files cannot be removed. To query a different file, please start a new chat.">
                  <WppActionButton onClick={() => clearFile(idx)} disabled={disabled}>
                    <WppIconClose size="s" />
                  </WppActionButton>
                </WppTooltip>
              ) : (
                <WppActionButton onClick={() => clearFile(idx)} disabled={disabled}>
                  <WppIconClose size="s" />
                </WppActionButton>
              )}
            </Flex>
          ))}
        </div>
      )}
    </>
  )
}
