import { signal } from '@preact/signals'

export const answerIsLoadingSignal = signal<boolean>(false)

export const clearConversationSignal = signal<boolean>(false)

export const clearInputFieldSignal = signal<boolean>(false)

export const resetAssistantSize = signal<boolean>(false)

export const manuallySetAssistantSize = signal<boolean>(false)
