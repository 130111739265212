import { InputChangeEventDetail } from '@platform-ui-kit/components-library'
import { InputMessageTypes } from '@platform-ui-kit/components-library/dist/types/types/common'
import { WppInputCustomEvent } from '@platform-ui-kit/components-library/loader'
import {
  WppInput,
  WppIconSearch,
  WppIconClose,
  WppTypography,
  WppButton,
  WppIconPlus,
  WppTabs,
  WppTab,
} from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'

import { useFetchApps } from 'api/assistant/queries/apps/useFetchApps'
import IconEmptyState from 'assets/icons/no-result.svg'
import { AppItem } from 'components/assistant/apps/appItem/AppItem'
import styles from 'components/assistant/assistantPopover/assistantAllApps/AssistantAllApps.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { useAppsContext } from 'hooks/useAppsContext'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'

export interface Props {
  onNewChat: () => void
  assistantWidth?: number
}

export const AssistantAllApps = ({ onNewChat, assistantWidth = 440 }: Props) => {
  const { osContext } = useOs()

  const { data: appsAPI, isLoading: isLoadingApps } = useFetchApps({
    params: {
      itemsPerPage: 5000,
      tenantId: osContext.tenant.id,
      externalUser: (osContext.userDetails as any)?.isExternal,
      enabled: true,
    },
  })

  // const { toolbarApps } = useApps()
  const { toolbarApps } = useAppsContext()

  const TABS = ['All', 'Pinned']

  const [searchInput, setSearchInput] = useState('')
  const [debouncedInput, setDebouncedInput] = useState('')

  const [searchErrorMessage, setSearchErrorMessage] = useState('')
  const [messageType, setMessageType] = useState<InputMessageTypes | undefined>(undefined)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchInput.length === 0 || (searchInput.length >= 3 && searchInput.length <= 20)) {
        setDebouncedInput(searchInput)
        setMessageType(undefined)
        setSearchErrorMessage('')
      } else {
        setDebouncedInput('')
        setMessageType('error')
        setSearchErrorMessage('Search value must be between 3 and 20 characters')
      }
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [searchInput])

  const handleSearchChange = (event: WppInputCustomEvent<InputChangeEventDetail>) => {
    setSearchInput(event.target.value)
  }

  const [selectedTab, setSelectedTab] = useState(TABS[0])

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab)

    if (tab === TABS[1]) {
      trackAnalytics({
        type: AnalyticsActionType.page,
        payload: EVENTS.SCREENS.ALL_TOLLS_PINNED,
      })
    } else if (tab === TABS[0]) {
      trackAnalytics({
        type: AnalyticsActionType.page,
        payload: EVENTS.SCREENS.ALL_TOLLS,
      })
    }
  }

  useEffect(() => {
    trackAnalytics({
      type: AnalyticsActionType.page,
      payload: EVENTS.SCREENS.ALL_TOLLS,
    })
  }, [])

  const allDisplayedApps = useMemo(() => {
    let finalApps = [...appsAPI]
    if (toolbarApps.pinnedApps.length) {
      finalApps = [...appsAPI, ...toolbarApps.pinnedApps.filter(app => !appsAPI.some(appApi => app.id === appApi.id))]
    }
    return finalApps
  }, [appsAPI, toolbarApps.pinnedApps])

  return (
    <div style={{ paddingLeft: '8px', width: '100%', position: 'relative', paddingRight: '20px' }}>
      <WppInput
        name="search"
        placeholder="Find a tool..."
        value={searchInput}
        message={searchErrorMessage}
        messageType={messageType}
        required
        className="cancel-drag"
        onWppChange={handleSearchChange}
      >
        <WppIconSearch slot="icon-start" aria-label="Search icon" />
        {searchInput.length !== 0 && (
          <WppIconClose
            slot="icon-end"
            aria-label="Clear icon"
            onClick={() => {
              setSearchInput('')
              setDebouncedInput('')
            }}
          />
        )}
      </WppInput>

      {!debouncedInput && !isLoadingApps && (
        <>
          <WppTabs
            value={selectedTab}
            onWppChange={e => handleTabChange(e.detail.value)}
            size="s"
            className={styles.tabs}
          >
            {TABS.map((tab, index) => (
              <WppTab key={index} value={tab}>
                {tab}
              </WppTab>
            ))}
          </WppTabs>
        </>
      )}

      <div className={clsx(styles.container, 'cancel-drag')}>
        {!isLoadingApps &&
          {
            All: (
              <>
                {allDisplayedApps
                  // .filter(app => !toolbarApps.pinnedApps.some(pinnedApp => app.id === pinnedApp.id))
                  .filter(app =>
                    debouncedInput.length ? app.name.toLowerCase().includes(debouncedInput.toLowerCase()) : true,
                  )
                  .map(app => (
                    <AppItem
                      key={app.id}
                      app={app}
                      assistantWidth={assistantWidth}
                      type="all-apps"
                      isAppPinned={toolbarApps?.pinnedApps.some(a => a.id === app.id)}
                    />
                  ))}
              </>
            ),
            Pinned: (
              <>
                {toolbarApps.pinnedApps
                  .filter(app =>
                    debouncedInput.length ? app.name.toLowerCase().includes(debouncedInput.toLowerCase()) : true,
                  )
                  .map(app => (
                    <AppItem
                      key={app.id}
                      app={app}
                      assistantWidth={assistantWidth}
                      type="all-apps"
                      isAppPinned={toolbarApps?.pinnedApps.some(a => a.id === app.id)}
                    />
                  ))}
              </>
            ),
          }[selectedTab]}
        {appsAPI.filter(app =>
          debouncedInput.length ? app.name.toLowerCase().includes(debouncedInput.toLowerCase()) : true,
        ).length === 0 && (
          <div className={styles.mt40}>
            <Flex justify="center">
              <img src={IconEmptyState} alt="No tools found" />
            </Flex>
            <Flex justify="center" className={styles.mt24}>
              <WppTypography type="m-body">No tools found</WppTypography>
            </Flex>
            <Flex justify="center" className={styles.mt8}>
              <WppTypography type="s-body">Try a different search term or ask AI</WppTypography>
            </Flex>
            <Flex justify="center" className={styles.mt24}>
              <WppButton onClick={onNewChat}>
                <WppIconPlus slot="icon-start" />
                New chat
              </WppButton>
            </Flex>
          </div>
        )}
      </div>
    </div>
  )
}
