import { WppDivider } from '@platform-ui-kit/components-library-react'
import { useState, useMemo, useEffect } from 'react'
import { useImmer } from 'use-immer'

import IconResize from 'assets/icons/resize.svg'
import styles from 'components/assistant/assistantPopover/assistantContent/AssistantContent.module.scss'
import { ChatWrapper } from 'components/assistant/chat/ChatWrapper'
import { SavedConversationsList } from 'components/assistant/savedConversationsList/SavedConversationsList'
import { AssistantTabs } from 'constants/ui'
import { ConversationProvider } from 'contexts/ConversationContext'
import { ConversationDto } from 'types/dto/ConversationDto'

import { useContentContext } from '../../../../hooks/useContentContext'
import { Flex } from '../../../common/flex/Flex'
import Sidebar from '../../chat/chatSidebar/Sidebar'
import { AssistantAllApps } from '../assistantAllApps/AssistantAllApps'
import { AssistantUserSettings } from '../assistantUserSettings/AssistantUserSettings'

export const AssistantContent = () => {
  const [searchString, setSearchString] = useState<string | undefined>(undefined)
  const [selectedConversation, updateSelectedConversation] = useImmer<ConversationDto | undefined>(undefined)
  const [conversation, updateConversation] = useImmer<ConversationDto | undefined>(undefined)
  const {
    selectedTabOuter,
    onSelectTabOuter,
    onSelectConversation,
    closeHistoryConversation,
    clearConversation,
    onUpdateUserSettings,
    assistantWidth,
    isAiAssistantOpen,
    assistantPosition,
  } = useContentContext()

  const handleSelectConversation = (conversation: ConversationDto | undefined, searchString: string | undefined) => {
    updateSelectedConversation(conversation)
    setSearchString(searchString)

    if (conversation) {
      onSelectConversation(conversation)
    } else {
      onSelectConversation(undefined)
    }
  }

  useEffect(() => {
    if (closeHistoryConversation) {
      updateSelectedConversation(undefined)
    }
  }, [closeHistoryConversation, updateSelectedConversation])

  useEffect(() => {
    if (clearConversation) {
      updateConversation(undefined)
    }
  }, [clearConversation, updateConversation])

  const saveToggleValue = useMemo(() => true, [])

  const handleDeleteConversation = (conversationParam: ConversationDto) => {
    if (conversation?.id === conversationParam?.id) {
      updateConversation(undefined)
    }
  }

  const handleClearConversation = () => {
    updateConversation(undefined)
  }

  const handleClearSelectedConversation = ({
    startNewConversation,
  }: {
    conversationId: string
    startNewConversation?: boolean
  }) => {
    updateSelectedConversation(undefined)
    onSelectConversation(undefined)
    setSearchString(undefined)

    if (startNewConversation) {
      onSelectTabOuter(AssistantTabs.CHAT)
    }
  }

  const conversationValues = {
    tab: selectedTabOuter,
    isAiAssistantOpen,
    [AssistantTabs.CHAT]: {
      conversation,
      isConversationSaved: saveToggleValue,
      updateConversation,
      onClearConversation: handleClearConversation,
      questionInputVisible: selectedTabOuter === AssistantTabs.CHAT,
    },
    [AssistantTabs.HISTORY]: {
      conversation: selectedConversation,
      isConversationSaved: saveToggleValue,
      updateConversation: updateSelectedConversation,
      searchString,
      onClearConversation: handleClearSelectedConversation,
      questionInputVisible: !!selectedConversation,
    },
  }

  return (
    <div className={styles.container}>
      {selectedTabOuter === AssistantTabs.CHAT && (
        <>
          <Sidebar
            assistantWidth={assistantWidth}
            isAiAssistantOpen={isAiAssistantOpen}
            assistantPosition={assistantPosition}
            onSelectTab={onSelectTabOuter}
          />
          <WppDivider className={styles.verticalDivider} />
        </>
      )}

      {(selectedTabOuter === AssistantTabs.CHAT ||
        (selectedTabOuter === AssistantTabs.HISTORY && selectedConversation)) && (
        <div style={{ display: 'block', width: '100%', height: '100%', overflow: 'hidden' }}>
          <ConversationProvider value={conversationValues}>
            <ChatWrapper />
          </ConversationProvider>
        </div>
      )}

      {selectedTabOuter === AssistantTabs.HISTORY && (
        <div
          style={
            selectedTabOuter === AssistantTabs.HISTORY && !selectedConversation
              ? { display: 'block', height: '100%', width: '100%', position: 'relative' }
              : { display: 'none' }
          }
        >
          <SavedConversationsList
            onSelectConversation={(conversation, searchString = undefined) =>
              handleSelectConversation(conversation, searchString)
            }
            onDeleteConversation={conversation => handleDeleteConversation(conversation)}
          />
        </div>
      )}

      {selectedTabOuter === AssistantTabs.SETTINGS && (
        <AssistantUserSettings onUpdateUserSettings={onUpdateUserSettings} />
      )}

      {selectedTabOuter === AssistantTabs.TOOLS && (
        <AssistantAllApps onNewChat={() => onSelectTabOuter(AssistantTabs.CHAT)} />
      )}
      {selectedTabOuter === AssistantTabs.CHAT && isAiAssistantOpen && (
        <Flex align="center" justify="center" className={styles.iconContainer}>
          <img src={IconResize} alt="resize" draggable={false} />
        </Flex>
      )}
    </div>
  )
}
