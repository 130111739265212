import { WppTypography } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import clsx from 'clsx'
import React, { useEffect } from 'react'

import styles from 'components/assistant/chat/mentions/Mentions.module.scss'
import { mentionDropdownPosition } from 'components/assistant/chat/mentions/utils/mentions'
import { useChatContext } from 'hooks/useChatContext'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'

export interface MentionOption {
  id: string
  display: string
  description: string
}

interface MentionsProps {
  onClick: (mention: string) => void
}

export const Mentions = ({ onClick }: MentionsProps) => {
  const { mentionOptionsFiltered, activeMentionId, mentionsContainerRef, mentionsRef, inputRef } = useChatContext()

  const handleMentionClick = (mention: string) => {
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: {
        action: EVENTS.ACTIONS.CLICK_MENTIONS,
        params: [{ key: 'pill_text', value: mention }],
      },
    })
    onClick(mention)
  }

  const handleMentionContainerPress = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  useEffect(() => {
    mentionDropdownPosition(inputRef.current, mentionsContainerRef.current)
  }, [mentionOptionsFiltered, inputRef, mentionsContainerRef])

  useEffect(() => {
    mentionOptionsFiltered.forEach(option => {
      if (mentionsRef.current && !mentionsRef.current[option.id]) {
        mentionsRef.current[option.id] = React.createRef()
      }
    })
  }, [mentionOptionsFiltered, mentionsRef])

  return (
    <div>
      {mentionOptionsFiltered.length > 0 && (
        <div
          ref={mentionsContainerRef}
          className={styles.mentionsContainer}
          onMouseEnter={() =>
            trackAnalytics({
              type: AnalyticsActionType.action,
              payload: EVENTS.ACTIONS.HOVER_MENTIONS,
            })
          }
          onMouseDown={handleMentionContainerPress}
        >
          {mentionOptionsFiltered.map(option => (
            <div
              className={clsx(styles.mention, activeMentionId === option.id && styles.active)}
              key={option.id}
              onClick={() => handleMentionClick(option.display)}
              ref={mentionsRef.current ? mentionsRef.current[option.id] : null}
            >
              <WppTypography className={styles.mentionText} tag="span" type="xs-strong">
                <span className={styles.mentionText}>@{option.display}</span>
                <span className={styles.descriptionText}>{option.description}</span>
              </WppTypography>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
