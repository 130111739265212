import { useContext } from 'react'

import { ContentContext } from 'contexts/ContentContext'

export const useContentContext = () => {
  const context = useContext(ContentContext)
  if (!context) {
    throw new Error('ContentContext must be used within a ContentProvider')
  }

  return {
    selectedTabOuter: context.selectedTabOuter,
    onSelectTabOuter: context.onSelectTabOuter,
    onSelectConversation: context.onSelectConversation,
    closeHistoryConversation: context.closeHistoryConversation,
    clearConversation: context.clearConversation,
    onUpdateUserSettings: context.onUpdateUserSettings,
    assistantWidth: context.assistantWidth,
    isAiAssistantOpen: context.isAiAssistantOpen,
    assistantPosition: context.assistantPosition,
    tableWidth: context.tableWidth,
    setTableWidth: context.setTableWidth,
    isTableInAnswer: context.isTableInAnswer ?? false,
    setIsTableInAnswer: context.setIsTableInAnswer,
    chatWidth: context.chatWidth,
    setChatWidth: context.setChatWidth,
    assistantSize: context.assistantSize,
    setAssistantSize: context.setAssistantSize,
    setAssistantPosition: context.setAssistantPosition,
    newChatWidth: context.newChatWidth,
    setNewChatWidth: context.setNewChatWidth,
  }
}
