import { WppTag, WppTooltip } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import React, { KeyboardEvent, useMemo } from 'react'
import { OverflowList } from 'react-overflow-list'

import styles from 'components/assistant/chat/Chat.module.scss'
import { Mentions } from 'components/assistant/chat/mentions/Mentions'
import { QuestionInput } from 'components/assistant/chat/questionInput/QuestionInput'
import { AssistantTabs } from 'constants/ui'
import { useChatContext } from 'hooks/useChatContext'
import { useConversationContext } from 'hooks/useConversationContext'
import { useHasPermissions } from 'hooks/useHasPermissions'

interface Props {
  onQuestionKeyDown: (event: KeyboardEvent<HTMLDivElement>) => void
  onInput: () => void
  onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void
  mentionClicked: (mention: string) => void
}

const ChatQuestionInput = ({ onQuestionKeyDown, onInput, onMouseDown, mentionClicked }: Props) => {
  const { hasAccessToChatAssistantConfig } = useHasPermissions()
  const { tab, conversation } = useConversationContext()
  const { onSubmitQuestion, mentionOptionsFiltered, mentionDropdownVisible } = useChatContext()

  const ItemRenderer = (item: string, index: number) => {
    // Remove the file path from the file name
    return <WppTag key={index} label={item.replace(/^[^/]+\//, '')} variant="neutral" />
  }

  const OverflowRenderer = (items: any[]) => {
    return (
      // Remove the file path from the file name and join all the file names
      <WppTooltip text={items.map(str => str.replace(/^[^/]+\//, '')).join('\n')}>
        <WppTag label={`+${items.length}`} variant="neutral" maxLabelLength={20000} />
      </WppTooltip>
    )
  }

  const isReadOnly = useMemo(
    () => tab === AssistantTabs.HISTORY && conversation?.ragFiles && conversation.ragFiles.length > 0,
    [conversation?.ragFiles, tab],
  )

  return (
    <form
      className={clsx(
        styles.questionInputWrapper,
        hasAccessToChatAssistantConfig && styles.questionInputWrapperFooterOffset,
      )}
      onSubmit={onSubmitQuestion}
    >
      {conversation?.ragFiles && conversation.ragFiles.length > 0 && (
        <OverflowList
          collapseFrom="end"
          minVisibleItems={0}
          items={conversation.ragFiles}
          itemRenderer={ItemRenderer}
          overflowRenderer={OverflowRenderer}
          className={styles.overflowList}
        />
      )}

      {!isReadOnly && (
        <div className={styles.inputBorder}>
          <QuestionInput onInput={onInput} onQuestionKeyDown={onQuestionKeyDown} onMouseDown={onMouseDown} />

          {mentionOptionsFiltered.length > 0 && mentionDropdownVisible && <Mentions onClick={mentionClicked} />}
        </div>
      )}
    </form>
  )
}

export default ChatQuestionInput
