// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oZl_3{width:100%;margin-bottom:8px;margin-right:20px;padding:12px 16px;background-color:var(--wpp-grey-color-200);border-radius:var(--wpp-border-radius-m);border-top-left-radius:0}.TedMA{fill:var(--wpp-grey-color-200)}.K_lgM{width:100%;white-space:pre-line}.tQkwU{color:var(--wpp-warning-color-500)}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatBubble/textAnswer/TextAnswer.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,iBAAA,CACA,iBAAA,CACA,iBAAA,CACA,0CAAA,CACA,wCAAA,CACA,wBAAA,CAGF,OACE,8BAAA,CAGF,OACE,UAAA,CACA,oBAAA,CAGF,OACE,kCAAA","sourcesContent":[".chatBubbleAnswer {\n  width: 100%;\n  margin-bottom: 8px;\n  margin-right: 20px;\n  padding: 12px 16px;\n  background-color: var(--wpp-grey-color-200);\n  border-radius: var(--wpp-border-radius-m);\n  border-top-left-radius: 0;\n}\n\n.rectangle {\n  fill: var(--wpp-grey-color-200);\n}\n\n.text {\n  width: 100%;\n  white-space: pre-line;\n}\n\n.errorText {\n  color: var(--wpp-warning-color-500);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatBubbleAnswer": "oZl_3",
	"rectangle": "TedMA",
	"text": "K_lgM",
	"errorText": "tQkwU"
};
export default ___CSS_LOADER_EXPORT___;
