import { AnalyticsActionType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import React, { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react'

import watsonxIcon from 'assets/icons/poweredByWatsonx.png'
import styles from 'components/assistant/chat/Chat.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { useChatContext } from 'hooks/useChatContext'
import { useConversationContext } from 'hooks/useConversationContext'
import { useMentions } from 'hooks/useMentions'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'
import { clearInputFieldSignal } from 'utils/signals'

import ChatDisplay from './ChatDisplay'
import ChatQuestionInput from './ChatQuestionInput'
import { filterMentions } from './mentions/utils/mentions'
import { parseMessageToString, setCursorToEnd } from './utils/utils'
import { useContentContext } from '../../../hooks/useContentContext'

export const Chat = () => {
  const { conversation, searchString } = useConversationContext()
  const { selectedTabOuter } = useContentContext()
  const {
    inputRef,
    mentionsContainerRef,
    mentionsRef,
    refScrollBottomDiv,
    refBubbles,
    onSubmitWrapper,
    isFetchingConversationMessages,
    messageId,
    questionCharCount,
    setQuestionCharCount,
    question,
    setQuestion,
    mentionDropdownVisible,
    setMentionDropdownVisible,
    mentionOptionsFiltered,
    setMentionOptionsFiltered,
    activeMentionId,
    setActiveMentionId,
  } = useChatContext()

  const { osContext } = useOs()
  const { mentionOptions } = useMentions()

  const [feedbackType, setFeedbackType] = useState<string | undefined>(undefined)
  const defaultValue = useRef('')

  const scrollToView = useCallback(
    (id?: string | undefined) => {
      if (!conversation) return

      const scrollToElement = (elementId: string) => {
        const scrollImmediateTimeout = setTimeout(() => {
          refBubbles.current[elementId]?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          })
        }, 10)

        return () => {
          clearTimeout(scrollImmediateTimeout)
        }
      }

      if (!feedbackType && !searchString && !isFetchingConversationMessages && !id) {
        refScrollBottomDiv.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        })
      } else if (feedbackType !== 'positive' && id) {
        return scrollToElement(id)
      } else if (feedbackType === 'positive') {
        return
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conversation, feedbackType, searchString, isFetchingConversationMessages],
  )

  useEffect(() => {
    let lastSearchMatchId = undefined
    if (searchString) {
      if (!conversation?.messages?.length || isFetchingConversationMessages) return
      lastSearchMatchId = conversation?.messages
        .slice()
        .reverse()
        .find(message => parseMessageToString(message).toLowerCase().includes(searchString.toLowerCase()))?.id
    }
    if (!conversation) {
      setFeedbackType(undefined)
    }

    scrollToView(lastSearchMatchId)
  }, [
    scrollToView,
    conversation,
    feedbackType,
    searchString,
    conversation?.messages,
    messageId,
    isFetchingConversationMessages,
  ])

  const handleMentionClick = (mention: string) => {
    const inputField = inputRef.current
    const mentionWithAt = `@${mention}`

    if (inputField !== null) {
      const atIndex = question.lastIndexOf('@')
      let inputValue = question.substring(0, atIndex) + mentionWithAt

      inputValue = replaceMentionsWithSpan(inputValue)
      inputField.innerHTML = inputValue.concat(' ')

      const inputValueWithoutHtml = inputField.innerHTML.replace(/<\/?[^>]+(>|$)/g, '')

      setQuestion(inputValueWithoutHtml)
      setQuestionCharCount(inputValueWithoutHtml.length)
      setMentionDropdownVisible(false)

      inputField.focus()
      setCursorToEnd(inputField)
    }
  }

  useEffect(() => {
    const activeMentionRef = mentionsRef.current[activeMentionId]
    const dropdownElement = mentionsContainerRef.current

    if (activeMentionRef && dropdownElement) {
      const dropdownRect = dropdownElement.getBoundingClientRect()
      const activeMentionRect = activeMentionRef?.current?.getBoundingClientRect()
      const activeMentionRectBottom = activeMentionRect?.bottom + 72

      if (activeMentionRectBottom > dropdownRect.bottom) {
        dropdownElement.scrollTop += activeMentionRectBottom - dropdownRect.bottom
      } else if (activeMentionRect?.top < dropdownRect.top) {
        dropdownElement.scrollTop += activeMentionRect.top - dropdownRect.top
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMentionId])

  /**
   * Handles the click of a prompt
   * @param prompt
   */
  const handlePromptClick = (prompt: string) => {
    const formattedPrompt = prompt.replace(/\[/g, '<mark>').replace(/]/g, '</mark>')
    const promptWithSpan = replaceMentionsWithSpan(formattedPrompt)
    const promptWithoutHtml = promptWithSpan.replace(/<\/?[^>]+(>|$)/g, '')

    inputRef?.current?.innerHTML !== undefined
      ? (inputRef.current.innerHTML = promptWithSpan)
      : (defaultValue.current = promptWithSpan)

    if (inputRef.current) {
      inputRef.current.scrollTop = inputRef.current.scrollHeight
      setCursorToEnd(inputRef.current)
    }

    setQuestion(promptWithoutHtml)
    setQuestionCharCount(promptWithoutHtml.length)

    if (mentionDropdownVisible) {
      setMentionDropdownVisible(false)
    }
  }
  /**
   * Handles the input of the question
   */
  const handleInput = () => {
    let inputRefValue = inputRef?.current?.innerText ?? ''
    const findNewLine = inputRefValue?.includes('\n')

    if (clearInputFieldSignal.value) {
      clearInputFieldSignal.value = false
    }

    if (findNewLine && inputRefValue?.length === 1 && inputRef?.current?.innerHTML !== undefined) {
      inputRefValue = ''
      inputRef.current.innerHTML = ''
    }

    setQuestion(inputRefValue)
    setQuestionCharCount(inputRefValue?.length)
  }

  const getLastWord = (input: string) => {
    const words = input.trim().split(' ')
    return words[words.length - 1]
  }

  const onQuestionKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      if (mentionDropdownVisible) {
        const selectedMention = mentionOptions.find(el => el.id === activeMentionId)

        if (selectedMention) {
          handleMentionClick(selectedMention.display)
        }

        setMentionDropdownVisible(false)
        return
      }

      setMentionDropdownVisible(false)
      onSubmitWrapper()
    }

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      if (mentionDropdownVisible) {
        event.preventDefault()

        const indexOfSelectedMention = mentionOptionsFiltered.findIndex(el => el.id === activeMentionId)

        if (indexOfSelectedMention > -1) {
          if (event.key === 'ArrowUp') {
            setActiveMentionId(
              mentionOptionsFiltered[
                indexOfSelectedMention === 0 ? mentionOptionsFiltered.length - 1 : indexOfSelectedMention - 1
              ].id,
            )
          } else if (event.key === 'ArrowDown') {
            setActiveMentionId(
              mentionOptionsFiltered[
                indexOfSelectedMention === mentionOptionsFiltered.length - 1 ? 0 : indexOfSelectedMention + 1
              ].id,
            )
          }
        }
        return
      }
    }

    if (event.key === '@') {
      setMentionDropdownVisible(true)
      trackAnalytics({
        type: AnalyticsActionType.action,
        payload: EVENTS.ACTIONS.ACTIVATE_MENTIONS,
      })
    }

    if (event.key === ' ') {
      const input = inputRef?.current?.innerText
      if (!input) {
        return
      }

      const lastWord = getLastWord(input)
      const mention = lastWord.slice(1)

      const isMention = () => {
        return lastWord.startsWith('@') && mentionOptions.some(option => option.display === mention)
      }

      if (isMention()) {
        handleMentionClick(mention)
      }
    }

    if (event.key === 'Backspace') {
      const inputField = inputRef.current

      if (inputField?.innerText?.length === 0) {
        setMentionDropdownVisible(false)
        return
      }
      const lastAtIndex = question.lastIndexOf('@')
      const mentionMatches = question.match(/@\w+/g)

      if (mentionMatches && mentionMatches.length > 0) {
        const lastMention = mentionMatches[mentionMatches.length - 1]

        if (lastMention && question.endsWith(lastMention)) {
          setMentionDropdownVisible(true)
        }
      }

      if (lastAtIndex === Number(questionCharCount) - 1) {
        setMentionDropdownVisible(false)
      }
    }
  }
  /**
   * Handles the mouse down event
   * @param event
   */
  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  function replaceMentionsWithSpan(input: string) {
    if (mentionOptions && mentionOptions.length > 0) {
      mentionOptions.forEach(mention => {
        const mentionWithAt = `@${mention.display}`
        const replacement = `<span>${mentionWithAt}</span>`
        input = input.replaceAll(mentionWithAt, replacement)
      })
    }

    return input
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
      setCursorToEnd(inputRef.current)
    }
  }, [inputRef, conversation, selectedTabOuter])

  useEffect(() => {
    if (mentionDropdownVisible) {
      const mention = question.lastIndexOf('@') !== -1 ? question.slice(question.lastIndexOf('@')) : ''
      const filteredMentions = filterMentions(mention, mentionOptions)

      setMentionOptionsFiltered(filteredMentions)
      if (filteredMentions.length) {
        setActiveMentionId(filteredMentions[0].id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, mentionDropdownVisible])

  return (
    <Flex gap={8} direction="column" justify="between" style={{ height: '100%' }}>
      <ChatDisplay handlePromptClick={handlePromptClick} scrollToView={scrollToView} />

      {osContext.tenant.id === '9d651f12-b2e4-45f7-аЗа2-97e73614507b' && (
        <img src={watsonxIcon} alt="" className={styles.watsonxIcon} />
      )}
      <ChatQuestionInput
        onQuestionKeyDown={onQuestionKeyDown}
        onInput={handleInput}
        onMouseDown={handleMouseDown}
        mentionClicked={handleMentionClick}
      />
    </Flex>
  )
}
