import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import styles from 'components/assistant/chat/chatBottomText/ChatBottomText.module.scss'
import { useConversationContext } from 'hooks/useConversationContext'

interface Props {
  tokenLimitReached: boolean
}

export const ChatBottomText = ({ tokenLimitReached }: Props) => {
  const { t } = useTranslation()

  const { conversation, isConversationSaved, onClearConversation } = useConversationContext()

  if (conversation && tokenLimitReached) {
    return (
      <>
        {isConversationSaved ? (
          <div>
            <WppTypography tag="span" type="s-body" className={styles.tokenLimitReachedText}>
              {t('assisstant_errors.char_limit_reached.saved_conversation')}{' '}
            </WppTypography>
            <button
              className={styles.clearConversationButton}
              onClick={() => onClearConversation({ conversationId: conversation.id, startNewConversation: true })}
            >
              <WppTypography tag="span" type="s-body" className={styles.clearConversationButtonText}>
                {t('start_new_conversation')}
              </WppTypography>
            </button>
          </div>
        ) : (
          <div>
            <WppTypography tag="span" type="s-body" className={styles.tokenLimitReachedText}>
              {t('assisstant_errors.char_limit_reached.not_saved_conversation.0')}{' '}
            </WppTypography>
            <button
              className={styles.clearConversationButton}
              onClick={() => onClearConversation({ conversationId: conversation.id, startNewConversation: false })}
            >
              <WppTypography tag="span" type="s-body" className={styles.clearConversationButtonText}>
                {t('clear_conversation')}
              </WppTypography>
            </button>
            <WppTypography tag="span" type="s-body" className={styles.tokenLimitReachedText}>
              {' '}
              {t('assisstant_errors.char_limit_reached.not_saved_conversation.1')}
            </WppTypography>
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <div />
    </>
  )
}
