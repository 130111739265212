import { useContext } from 'react'

import { ConversationContext } from 'contexts/ConversationContext'

export const useConversationContext = () => {
  const context = useContext(ConversationContext)
  if (!context) {
    throw new Error('useChatContext must be used within a ChatProvider')
  }

  return {
    ...context[context.tab === 'HISTORY' ? 'HISTORY' : 'CHAT'],
    tab: context.tab,
    isAiAssistantOpen: context.isAiAssistantOpen,
  }
}
