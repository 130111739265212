import {
  WppActionButton,
  WppIconAdd,
  WppIconHistory,
  WppIconGear,
  WppTooltip,
} from '@platform-ui-kit/components-library-react'
import { useEffect, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { AssistantTabs } from 'constants/ui'
import { answerIsLoadingSignal, clearInputFieldSignal } from 'utils/signals'

interface Props {
  onSelect: (item: AssistantTabs) => void
}

const ListItems = [
  {
    tab: AssistantTabs.CHAT,
    icon: <WppIconAdd slot="icon-start" color="var(--wpp-grey-color-800)" />,
    label: 'New Chat',
    tooltip: 'New chat',
    id: 'chat_new',
  },
  {
    tab: AssistantTabs.HISTORY,
    icon: <WppIconHistory slot="icon-start" color="var(--wpp-grey-color-800)" />,
    label: 'History',
    tooltip: 'Chat history',
    id: 'chat_history',
  },
  {
    tab: AssistantTabs.SETTINGS,
    icon: <WppIconGear slot="icon-start" color="var(--wpp-grey-color-800)" />,
    label: 'Settings',
    tooltip: 'Chat settings',
    id: 'chat_settings',
  },
]

export const AssistantContextMenu = ({ onSelect }: Props) => {
  const [disableButton, setDisableButton] = useState(false)

  useEffect(() => {
    answerIsLoadingSignal.subscribe(v => {
      if (v !== disableButton) {
        setTimeout(() => {
          setDisableButton(v)
        }, 10)
      }
    })
  }, [disableButton])

  return (
    <>
      <Flex gap={2}>
        {ListItems.map((item, idx) => (
          <WppTooltip text={item.tooltip} key={idx}>
            <WppActionButton
              disabled={item.tab === AssistantTabs.CHAT && disableButton}
              variant="secondary"
              onClick={() => {
                clearInputFieldSignal.value = item.id === 'chat_new'
                onSelect(item.tab)
              }}
            >
              {item.icon && item.icon}
            </WppActionButton>
          </WppTooltip>
        ))}
      </Flex>
    </>
  )
}
