import { assistantApi } from 'api'
import { AppAPI } from 'types/app/app'

export const fetchApps = ({
  tenantId,
  itemsPerPage,
  externalUser,
  enabled,
}: {
  tenantId?: string
  itemsPerPage: number
  externalUser: boolean
  enabled?: boolean
}) =>
  assistantApi.get<{ data: AppAPI[] }>('/apps', {
    params: { tenantId, itemsPerPage, externalUser, enabled },
  })
